import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Container,
} from '@mui/material';
import Banner from '../../components/banner';
import ListNews from '../../components/list/list-news';
import CardSmallNewsHighlight from '../../components/card-group/card-small-news-highlight';
import CardSmall from '../../components/card/card-small';
import CardSmalleList from '../../components/card-group/card-small-list';
import ListLiveScoreList from '../../components/list/list-livescore-list';
import VideoSection from '../../components/section/video-section';
import PlayerSection from '../../components/section/player-section';
import AdMobile from '../../components/ad-mobile';
import ad2 from '../../gpp-02.jpg';

export default function HomeXSLayout({ news_highlight, news_card, news_list, match_list, video_list, live_list }) {
    return (
        <>
            <Container
                disableGutters={true}
                maxWidth="xs"
                sx={{
                    display: {
                        md: 'none',
                        xs: 'flex',
                        sm: 'none',
                    },
                    flexDirection: 'column',
                    // columnGap: 2,
                    // rowGap: 2,
                    // pl: 2,
                    // pr: 2
                }}
            >

                <Grid
                    container
                // spacing={1}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Banner />
                    </Grid>
                </Grid>
            </Container>
            <Container
                disableGutters={true}
                maxWidth="xs"
                sx={{
                    display: {
                        md: 'none',
                        xs: 'flex',
                        sm: 'none',
                    },
                    flexDirection: 'column',
                    columnGap: 2,
                    rowGap: 2,
                    pl: 2,
                    pr: 2
                }}

            >
                <Grid
                    container
                    spacing={1}
                >
                    {/* <Grid
                        item
                        xs={12}
                    >
                        <ListLiveScoreList
                            match_list={match_list}
                        />
                    </Grid> */}
                    <Grid
                        item
                        xs={12}
                    >
                        <AdMobile />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <PlayerSection
                            section_title={"Live"}
                            content_list={live_list}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <CardSmallNewsHighlight
                            news_highlight={news_highlight}
                            title={'ไฮไลท์ข่าว'}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <PlayerSection
                            section_title={"Video"}
                            content_list={video_list}
                        />
                    </Grid>

                    <Grid
                        item
                        sm={12}
                    >
                        <img
                            src={ad2}
                            style={{ position: "relative", width: '100%' }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <ListNews
                            news={news_list}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <CardSmalleList
                            news={news_card}
                        />
                        {/* {
                            news_list.map((item, i) => { 
                                <CardSmall
                                    card_image={item.news_thumbnail}
                                    card_header={item.news_subject}
                                    card_body={item.news_body}
                                    chip_label={item.category_name}
                                    card_footer={item.approvedAt}
                                    target_id={item.news_id}
                                />
                            })
                        } */}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}