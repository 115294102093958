import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Container,
    Button,
    TextField,
    IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CardSmalleList from '../../components/card-group/card-small-list-app';
import CardSmallNewsHighlight from '../../components/card-group/card-small-news-highlight';
import CategoryButtonGroup from '../../components/button-group/category-button-group';
import NewsInfiniteSectionApp from '../../components/section/news-infinite-section-App';
import AdMobile from '../../components/ad-mobile';
import ad2 from '../../gpp-02.jpg';
import CardSmallNewsHighlightApp from '../../components/card-group/card-small-news-highlight-app';

export default function NewsListAPPLayout({
    news_all,
    search_str,
    setSearchStr,
    handleSearchSubmit,
    selected_news_category,
    handleCategorySelected
}) {
    const [max_news, setMaxNews] = useState(18)

    const handleLoadMore = () => {
        let temp_max_news = max_news + 8
        setMaxNews(temp_max_news)
    }
    return (
        <>
            <Container
                disableGutters={true}
                maxWidth="xs"
                sx={{
                    display: {
                        md: 'none',
                        xs: 'flex',
                        sm: 'flex',
                    },
                    flexDirection: 'column',
                }}
            >
                <Grid
                    direction="row"
                    container
                    // spacing={2}
                    rowSpacing={2}
                    sx={{
                        pl:1
                    }}
                >

                    <Grid
                        item
                        xs={12}
                    >
                        <AdMobile />
                    </Grid>
                </Grid>
                <Grid
                    direction="row"
                    container
                    spacing={2}
                    rowSpacing={2}
                    sx={{
                        flexDirection: 'column',
                        columnGap: 2,
                        rowGap: 2,
                        pl: 1,
                        pr: 0,
                        pt: 2,
                        pb: 2
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        direction="row"
                        container
                        spacing={2}
                        rowSpacing={2}
                    >
                        {/* <Grid
                            item
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                id="search_str"
                                className='text-field-filter'
                                // label="ค้นหาข่าว"
                                type="text"
                                sx={{ input: { color: 'white', fontFamily: 'IBM Plex Sans Thai' } }}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={search_str}
                                onChange={event => setSearchStr(event.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <IconButton
                                            sx={{ color: 'white' }}
                                            onClick={handleSearchSubmit}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                }}
                            />
                        </Grid> */}
                        <Grid
                            item
                            xs={12}
                        >
                            <CardSmallNewsHighlightApp
                                news_highlight={news_all.slice(0, 3)}
                                title={"ข่าวล่าสุด"}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <NewsInfiniteSectionApp
                                news_list={news_all}
                                start_index={3}
                            />
                        </Grid>

                        {/* <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button
                                onClick={() => handleLoadMore()}
                                sx={{
                                    color: 'white',
                                    display: 'block',
                                    bgcolor: '#555555'
                                }}
                                size={'large'}

                            >
                                Load More
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}