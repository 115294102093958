import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Container,
    Button,
    TextField,
    IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CardBigNewsHighlight from '../../components/card-group/card-big-news-highlight';
import CardMiddleCol from '../../components/card-group/card-middle-col';
import CardSmalleList from '../../components/card-group/card-small-list-app';
import CategoryButtonGroup from '../../components/button-group/category-button-group';
import NewsInfiniteSection from '../../components/section/news-infinite-section';
import AdMobile from '../../components/ad-mobile';
import ad2 from '../../gpp-02.jpg';

export default function NewsListSMLayout({
    news_all,
    search_str,
    setSearchStr,
    handleSearchSubmit,
    selected_news_category,
    handleCategorySelected
}) {
    const [max_news, setMaxNews] = useState(18)

    const handleLoadMore = () => {
        let temp_max_news = max_news + 8
        setMaxNews(temp_max_news)
    }
    return (
        <>
            <Container
                disableGutters={true}
                maxWidth="xl"
                sx={{
                    display: {
                        md: 'none',
                        xs: 'none',
                        sm: 'flex',
                    },
                    flexDirection: 'column',
                    columnGap: 2,
                    rowGap: 2,
                    pl: 2,
                    pr: 2,
                    pt: 2,
                    pb: 2
                }}
            >
                <Grid
                    direction="row"
                    container
                    spacing={2}
                    rowSpacing={2}
                >
                    <Grid
                        item
                        sm={12}
                    >
                        <AdMobile />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        direction="row"
                        container
                        spacing={2}
                        rowSpacing={2}
                    >
                        <Grid
                            item
                            sm={12}
                        >
                            <TextField
                                fullWidth
                                id="search_str"
                                className='text-field-filter'
                                // label="ค้นหาข่าว"
                                type="text"
                                sx={{ input: { color: 'white', fontFamily: 'IBM Plex Sans Thai' } }}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={search_str}
                                onChange={event => setSearchStr(event.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <IconButton
                                            sx={{ color: 'white' }}
                                            onClick={handleSearchSubmit}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <CategoryButtonGroup
                                selected_news_category={selected_news_category}
                                handleCategorySelected={handleCategorySelected}
                            />
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <CardBigNewsHighlight
                                news_highlight={news_all.slice(0, 3)}
                                title={"ข่าวล่าสุด"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={12}
                        >
                            <img
                                src={ad2}
                                style={{ position: "relative", width: '100%' }}
                            />
                        </Grid>
                        {
                            Array.from({ length: 3 }, (item, index) => {
                                return (
                                    <Grid
                                        item
                                        sm={12}
                                    >
                                        <CardMiddleCol
                                            news={news_all.slice((index + 1) * 3, (index + 2) * 3)}
                                        />
                                    </Grid>
                                )
                            })
                        }

                        <Grid
                            item
                            sm={12}
                        >
                            <NewsInfiniteSection
                                news_list={news_all}
                                start_index={12}
                            />
                        </Grid>
                        {/* <Grid
                            item
                            sm={12}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button
                                onClick={() => handleLoadMore()}
                                sx={{
                                    color: 'white',
                                    display: 'block',
                                    bgcolor: '#555555'
                                }}
                                size={'large'}

                            >
                                Load More
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}